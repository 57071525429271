.title_block_title{
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  color: #044582;
}

.title_block_sub_title{
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #7B889A;
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

@media screen and (max-width: 1024px){

  .title_block_sub_title{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #7B889A;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 767px){
  .title_block_sub_title{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #7B889A;
    width: 80%;
    text-align: start;
    margin-top: 1rem;
    margin-left: 4vw;

  }

  .title_block_title{
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #044582;
    text-align: start;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 414px){
  .title_block_title{
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 40px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #044582;
    text-align: start;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 330px){
  .title_block_title{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #044582;
    text-align: start;
    margin-left: 4vw;
  }

  .wrapper_title_block{
    margin-top: 30px;
  }
}

