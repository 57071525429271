@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  width: 50px!important;
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white!important;
}

body {
  font-family: 'Exo 2', sans-serif;
  font-size: 12px;

}

li {
  list-style-type: none;
}

p {
  margin: 0;
  padding: 0;

}

.wrapper {
  position: relative;
}

a{
  cursor: pointer;
}
.btn_org_circle {
  background: #F47D0E;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  text-rendering: auto;


  & p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 0px auto;
    padding: 15px 20px;


  }
}


.btn_org {
  background: #F47D0E;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  text-rendering: auto;


  & p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 0px auto;
    padding: 15px 20px;


  }
}

.btn_blue {
  background: #0866BD;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  text-rendering: auto;


  & p {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 0px auto;
    padding: 18px 25px;


  }
}

.header_ul {
  display: inline;
}


.wrapper_inner_container {
  max-width: 1200px;
  margin: auto;
}

.carousel-indicators {
  max-width: 1200px !important;
  margin: auto !important;
  justify-content: normal !important;
  bottom: 5vh !important;
}

.carousel-indicators li {
  width: 80px !important;
  margin-right: 30px !important;
  height: 5px !important;

}

@media screen and (max-width: 1024px) {
  .btn_org {
    background: #F47D0E;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: button;
    text-rendering: auto;


    & p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      margin: 0px auto;
      padding: 10px 15px;


    }
  }

  .btn_org_circle {
    background: #F47D0E;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: button;
    text-rendering: auto;


    & p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      margin: 0px auto;
      padding: 10px 15px;


    }
  }
}

@media screen and (max-width: 1200px) {
  .wrapper_inner_container {
    max-width: 970px;

  }

  .carousel-indicators {
    max-width: 970px !important;

  }
}

@media screen and (max-width: 992px) {
  .wrapper_inner_container {
    max-width: 820px;

  }
  .carousel-indicators {
    max-width: 820px !important;

  }

  .wrapper_padding__left {
    padding-left: 30px!important;
  }

}

@media screen and (max-width: 768px) {
  .wrapper_inner_container {
    width: 100%;
    overflow: hidden;

  }
  .wrapper_padding__left {
    padding-left: 4vw!important;
  }

  .carousel-indicators {
    width: 100% !important;
    padding-left:4vw!important;

  }

  .btn_org_circle {
    background: #F47D0E;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: button;
    text-rendering: auto;
    border-radius: 100px;

    & p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      margin: 0px auto;
      padding: 10px 15px;
      width: 48px;
      height: 48px;

    }
  }

  .btn_org {
    background: #F47D0E;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-appearance: button;
    text-rendering: auto;


    & p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 125%;
      letter-spacing: 0.02em;
      color: #FFFFFF;
      margin: 0px auto;
      padding: 14px 35px;;


    }
  }
}


.wrapper_padding__left {
  padding-left: 75px;
}

button:active, button:focus {
  outline: none !important;
  border: none !important;
}

button::-moz-focus-inner {
  border: 0 !important;
  outline: none !important;
}

button, input {
  border-width: 0px;
}

a:hover {
  text-decoration: none;
}

button :hover {
  cursor: pointer;
}

a :hover {
  cursor: pointer;
}


.input-range__label-container {

  display: none!important;
}

.app{
  height: 100%;
}

.swiper-button-prev, .swiper-button-next{
  top: 90%!important;
}

.header_phone_block{
  display: block;
}

.header_phone_block.mb{
  margin-bottom: 5px;
}