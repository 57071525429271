.wrapper_more_detail_block{
  position: relative;
  width: 100%;
  height: 412px;
  background: #EBF0F4;

  & .more_banner_back{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

  }

}

.more_banner_content_text{
  grid-area: content;
}

.more_detail_title{
  font-size: 48px;
  line-height: 56px;
  /* or 117% */
  margin-top: 60px;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.more_detail_sub_title{
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  letter-spacing: 0.03em;
width: 80%;
  color: #FFFFFF;

}

.wrapper_more_detail_text{
  position: relative;
  z-index: 55;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  height: 100%;
  grid-template-areas: "content img";
}


.wrapper_more_detail_img{
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: img;


}

.more_banner_car{
  position: absolute;
  top: -9vh;
  left: 2vw;
  right: 0;
  bottom: 0;
}

.btn_more_detail{
  margin-top: 20px;
  & p {
    text-decoration: none; /* Отменяем подчеркивание у ссылки */
  }



}

@media screen and (max-width: 1200px){
  .more_detail_title{
    font-size: 36px;
    line-height: 52px;
    /* or 117% */
    margin-top: 60px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
  .more_detail_sub_title{
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    /* or 133% */
    letter-spacing: 0.03em;
    width: 80%;
    color: #FFFFFF;

  }

  .more_detail_title{
    font-size: 48px;
    line-height: 56px;
    /* or 117% */
    margin-top: 40px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FFFFFF;
  }

  .wrapper_more_detail_block{
    position: relative;
    width: 100%;

    background: #EBF0F4;

    & .more_banner_back{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

    }

  }

}


@media screen and (max-width: 1024px) {
  .more_banner_car{
    position: absolute;
    top: 14vh;
    left:1vw;
    right: 0;
    bottom: 0;
    width: 315px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper_more_detail_text{
    position: relative;
    z-index: 55;
    display: flex;
    height: 100%;
  }


  .wrapper_more_detail_text{
    position: relative;
    z-index: 55;
    display: grid;
    grid-template-areas: "img" "content" ;
    grid-template-columns: 1fr ;
    grid-template-rows:  140px 1fr ;
    height: 100%;
  }

  .more_banner_car{
    position: absolute;
    top: -18vh;
    left: 32vw;
    right: 0;
    bottom: 0;
    width: 324px;
    }

  .more_detail_title{
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;
  }

  .more_detail_sub_title{
    font-size: 16px;
    line-height: 20px;

  }

  .more_banner_content_text{
    padding-left: 4vw;
  }

}

@media screen and (max-width: 480px) {
  .more_banner_car{
    position: absolute;
    top: -18vh;
    left: 12vw;
    right: 0;
    bottom: 0;
    width: 324px;
  }

  .wrapper_more_detail_text{
    position: relative;
    z-index: 55;
    display: grid;
    grid-template-areas: "img" "content" ;
    grid-template-columns: 1fr ;
    grid-template-rows:  70px 1fr ;
    height: 100%;
  }
}

@media screen and (max-width: 414px) {
  .more_banner_car{
    position: absolute;
    top: -18vh;
    left: 16vw;
    right: 0;
    bottom: 0;
    width: 296px;
  }

  .wrapper_more_detail_text{
    position: relative;
    z-index: 55;
    display: grid;
    grid-template-areas: "img" "content" ;
    grid-template-columns: 1fr ;
    grid-template-rows:  80px 1fr ;
    height: 100%;
  }

}



@media screen and (max-width: 375px) {
  .more_banner_car{
    position: absolute;
    top: -18vh;
    left: 16vw;
    right: 0;
    bottom: 0;
    width: 270px;
  }

  .more_detail_title{
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
  }

}

@media screen and (max-width: 320px) {
  .more_banner_car{
    position: absolute;
    top: -18vh;
    left: 16vw;
    right: 0;
    bottom: 0;
    width: 270px;
  }

  .more_detail_title{
    font-weight: 600;
    font-size: 26px;
    line-height: 38px;
  }

}