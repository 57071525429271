.wrapper_form_call {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  background: rgba(192, 192, 192, 0.4);
  z-index: 111111111;
  height: 100%;
}

.load{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form_call{
  max-width: 410px;
  background: white;
  padding: 74px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form_call_sub_title{

  margin-bottom: 10px;
}

.form_call_politicy{
  max-width: 1070px;
  background: white;
  max-height: 80vh;
  height: 80vh;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}

.politicy_text{
  font-size: 16px;
  line-height:24px;
  overflow: scroll;
}

.text_form_call{
  font-size: 18px;
  line-height: 22px;
  color: #7B889A;

}

.form_call_close{
  position: absolute;
  top: 28px;
  right: 18px;
  cursor: pointer;
}

.form_call_title{
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #044582;
}

.form_call_sub_title{
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
margin-top: 15px;
  color: #7B889A;
}

.form_call_btn{
  background:  #044582;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: button;
  text-rendering: auto;
  margin-top: 50px;

  & p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin: 0px auto;
    padding: 18px 25px;
  }

  &:hover{
    background:  #1362AB;
    transition: 0.3s;
  }
}

.form_call_input{
  border: 1px solid #CDCDCD;
margin-top: 5px;
  min-height: 50px;
  width: 100%;
  padding-left: 10px;
  font-size: 24px;
  color: #7B889A;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px){
  .form_call{
    max-width: 410px;
    background: white;
    padding: 74px 30px;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .form_call_sub_title{

    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1920px){
  .form_call{
    max-width: 410px;
    background: white;
    padding: 74px 30px;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .form_call_sub_title{

    margin-bottom: 10px;
  }
}

@media screen and (max-width: 480px){
  .form_call{
    max-width: 410px;
    background: white;
    padding: 74px 30px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .form_call_sub_title{

    margin-bottom: 10px;
  }
}

@media screen and (max-width: 414px){
  .form_call{
    max-width: 380px;
    background: white;
    padding: 74px 30px;
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .form_call_sub_title{

    margin-bottom: 10px;
  }
}

@media screen and (max-width: 375px){
  .form_call{
    width: 100%;
    max-width: 333px;
    background: white;
    padding: 60px 19px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .form_call_sub_title{
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #7B889A;
    margin-bottom: 10px;
  }

  .form_call_title{
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #044582;
  }
}

@media screen and (max-width: 320px){
  .form_call{
    width: 100%;
    max-width: 295px;
    background: white;
    padding: 60px 19px;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .form_call_title{
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #044582;
  }
}

