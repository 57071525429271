.wrapper_calculator_block{
  position: relative;
  width: 100%;
  background: #EBF0F4;
}

.banner_calculator{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 519px;
  width: 100%;
}

.calculator_banner_title{
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;

  color: #FFFFFF;
}

.wrapper_banner_text{
  position: relative;
  z-index: 44;
  padding-top: 60px;
}

.calculator_banner_sub_title{
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  /* identical to box height */


  color: #FFFFFF;

}

.wrapper_calculator__absolute{
  margin-top: 40px;
  position: relative;
}


@media screen and (max-width: 768px){
  .calculator_banner_title{
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  .calculator_banner_sub_title{
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #FFFFFF;

  }


}

.rc-slider-handle{
  background: linear-gradient(180deg, #0866bd, #0866bd);
  border: 2px solid #0866bd!important;
  height: 20px!important;
  width: 20px!important;
  opacity: 1!important;
  box-shadow: none!important;
  transition: all 0.1s ease 0s;
  top: 3px;
}

.rc-slider-handle-dragging{
  box-shadow: none!important;
  transition: all 0.1s ease 0s;
}

.rc-slider-track, .rc-slider-tracks{
  transition: all 0.1s ease 0s;
  background: linear-gradient(180deg, #0866bd, #0866bd);
  height: 6px!important;
}

