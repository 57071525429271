.wrapper_calculator{
  background: white;
position: relative;
  display: grid;
  grid-template-areas: "range cargo "
                       "service  price"
                       "service btn" ;
  grid-template-columns: 1fr 1fr ;
  grid-gap:30px;
  grid-template-rows: auto auto auto;

  width: 100%;
  padding: 78px 50px;







}

.wrapper_range{
  grid-area: range;
width: 100%;
}

.wrapper_add_service{
  grid-area: service ;
  width: 100%;
}

.wrapper_btn_calculator{
  grid-area: btn ;
  width: 100%;
}

.wrapper_loading_cargo{
  grid-area: cargo;
  width: 100%;
}



.wrapper_calculator_price{
  grid-area: price ;
  width: 100%;

}

.wrapper_info_range{
  display: flex;
  justify-content: space-between;
}

.range_atribute{
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 48px;
  /* identical to box height, or 200% */
display: flex;
  color: #0C1D2E;
  justify-content: space-between;
}

.range_value{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height, or 133% */
  margin-bottom: 10px;
  text-align: right;

  color: #0866BD;

}

.range_calculator{
  width: 100%;
  margin-top: 10px;
}

.size{
  margin-top: 47px;
  margin-bottom: 20px;
}

.fihish_price{
  font-style: normal;
  font-weight: 600;
  font-size: 56px;

  /* identical to box height, or 129% */
  line-height: 48px;

  color: #0866BD;
  margin-bottom: 0;

}

.fihish_price_text{
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */
margin-top: 10px;

  color: #0866BD;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */


  color: #0866BD;

  margin-bottom: 0;
}

.input_text{
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #0C1D2E;
  margin-bottom: 25px;
  margin-left: 5px;
}

.input_text__blue{
  color:  #0866BD;
  font-size: 14px;
}

/* CHECKBOX */



.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.custom-checkbox+label::before {
  content: '';
  display: flex;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 5px solid #ffffff;
  border-radius: 10px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
  cursor: pointer;
}

.custom-checkbox:checked+label::before {
  
  background-color: #0b76ef;
  margin-right: 0.5em;
}
/*___________checkbox______________*/


/*________CHECKBOX_RADIO_________*/



.custom-checkbox_radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox_radio+label {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.custom-checkbox_radio+label::before {
  content: '';
  display: flex;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 5px solid #ffffff;
  border-radius: 100px;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
  cursor: pointer;
}

.custom-checkbox_radio:checked+label::before {

  background-color: #0b76ef;
  margin-right: 0.5em;
}




/*________CHECKBOX_RADIO_________*/

@media screen and (max-width: 1024px){
  .wrapper_calculator{
    background: white;

    display: grid;
    grid-template-areas: "range range " " cargo cargo"
                       "service service"
                       " btn price" ;
    grid-template-columns: 1fr  ;
    grid-template-rows: auto;
grid-gap: 10px;
    width: 100%;
    padding: 25px 17vw;







  }
  .custom-checkbox_radio+label::before {
    content: '';
    display: flex;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 5px solid #ffffff;
    border-radius: 100px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
    cursor: pointer;
  }

  .custom-checkbox +label::before {
    content: '';
    display: flex;
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 5px solid #ffffff;
    border-radius: 4px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {

  .custom-checkbox_radio+label::before {
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 5px solid #ffffff;
    border-radius: 100px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
    cursor: pointer;
  }

  .custom-checkbox+label::before {
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 5px solid #ffffff;
    border-radius:5px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
    cursor: pointer;
  }


  .wrapper_calculator_price {
   position: absolute;
    background: #0866BD;
    border-radius: 5px;
    padding: 20px 0px;
    text-align: center;
    margin: auto;top: -8vw;
  }

  .fihish_price{
    color: white;
    font-size: 34px;
    line-height: 24px;
  }
  .fihish_price_text{
    color: white;
  }


  .input_text {
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    margin-left: 5px;
  }

  .range_atribute {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 24px;;
    display: flex;
    justify-content: space-between;
  }
  .range_value {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;

  }

  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .custom-checkbox + label {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }


  .custom-checkbox:checked + label::before {

    background-color: #0b76ef;
    margin-right: 0.5em;
  }

  .wrapper_calculator {
    background: white;

    display: grid;
    grid-template-areas: " price " "range" "  cargo"
                       " service"
                       " btn ";
    grid-template-columns: 1fr;
    grid-template-rows: 120px auto;
    grid-gap: 10px;
    width: 100%;
    padding: 25px 6vw;

  }
}


@media screen and (max-width: 375px) {

  .custom-checkbox + label::before {
    content: '';
    display: flex;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 5px solid #ffffff;
    border-radius: 10px;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    box-shadow: 0 0 0 0.2rem rgb(206, 224, 242);
    cursor: pointer;
  }

  .wrapper_calculator {
    background: white;

    display: grid;
    grid-template-areas: " price " "range" "  cargo"
                       " service"
                       " btn ";
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto;
    grid-gap: 10px;
    width: 100%;
    padding: 25px 6vw;

  }

  .wrapper_calculator_price {
    position: absolute;
    background: #0866BD;
    border-radius: 5px;
    padding: 20px 0px;
    text-align: center;
    margin: auto;
    top: -10vw;
  }

  .fihish_price {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: white;
    margin-bottom: 0;
  }

  .fihish_price_text {
   font-size: 18px;
    line-height: 24px;

  }
}

.input-range__track {

  height: 6px!important;

}

.input-range__slider{
  background: linear-gradient(180deg, #0866BD 0%, #0866BD 100%);
  border: 2px solid #0866BD;
  width: 20px;
  height: 20px;
}

.container_add_service{
  position: relative;
}

.add_service_cross{


}

.cross{
  width: 30px;
}

.form_cal{
  transition: 2s;
}