$milk_color :  #EBF0F4;
$text_color_blue:#044582;
$text_color_grey: #7B889A;

.wrapper_advantages_block{
  background: $milk_color;
  width: 100%;
}

.advantages_block{
  padding-top: 65px;
  padding-bottom: 90px;
}

.advantages_item{
  position: relative;
  height: 322px;
  display: flex;
  align-items: center;

  &:hover{

    .advantages_item_text img {
      filter: brightness(11.5);

    }

    .advantages_item_text_title{
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      color: #ffffff;
      margin-top: 10px;
    }

    .advantages_item_text_sub_title{
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;

    }

  }
}

.advantages_item:hover{
  position: relative;
  height: 322px;
  display: flex;
  align-items: center;
  background: #003377;
transition:0.4s}

.advantages_item_back{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: soft-light;

}

.wrapper_advantages_items{
  margin-top: 20px;
}

.advantages_item_text{
  z-index: 44;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 1.5rem;
  padding-right: 1rem;
}

.advantages_item_text_title{
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #1B345B;
  margin-top: 10px;
}

.advantages_item_text_sub_title{
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #1B345B;

}

/*GRID */

.wrapper_advantages_items{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
}


@media screen and (max-width: 1024px){
  .wrapper_advantages_items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    grid-gap: 20px;
  }
}


@media screen and (max-width: 768px){


  .advantages_item_text img {
    filter: brightness(11.5);

  }


  .advantages_item{
    position: relative;
    height: 322px;
    display: flex;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 25px;
    align-items: center;
    background: #003377;
    transition:0.4s}

  .advantages_item_text_title{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
    margin-top: 10px;
  }

  .advantages_item_text_sub_title{
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;

  }

  .wrapper_advantages_items{
    padding-left: 4vw;
  }

}


@media screen and (max-width: 480px){
.advantages_item{
  margin-top: 25px;
margin-left: 4vw;
  margin-right: 4vw;
}



}

@media screen and (max-width: 414px){
  .advantages_block{
    padding-top: 25px;
    padding-bottom: 90px;
  }
}