.wrapper_about{
  background: #EBF0F4;
  padding-top: 200px;
  padding-bottom: 150px;
}

.about_title{
  grid-area: title;
}

.about_advantages__item img{
  max-width: 50px;
}

.about_advantages{
  grid-area: advantages;
  margin-top: 50px;
display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.about_image_slide{
  width:inherit;
  object-fit: cover;
  height: 500px;

  @media screen and (max-width: 768px){
    max-height: 400px;
  }

  @media screen and (max-width: 376px){
    max-height: 350px;
  }
}


.about_image{
  grid-area: image;
  width: 100%;

}

.wrapper_about__grid{
  display: grid;
  grid-template-areas: "title image" " advantages image";
  grid-template-columns: 1fr 70%;
  grid-template-rows: 1fr;
  grid-gap: 50px;


}



.about_title{
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  /* identical to box height, or 117% */


  color: #044582;
}

.about_sub_title{
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  margin-top: 1rem;
  /* or 144% */


  color: #7B889A;

}

.wrapper_about_title{
  padding-bottom: 30px;

}

.about_advantages_title{
 margin-top: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* or 140% */


  color: #0C1D2E;
}

.about_advantages_sub_title{
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 144% */
  margin-top: 1rem;


  color: #7B889A;

}


@media screen and (max-width: 1024px){
  .wrapper_about{
    background: #EBF0F4;
    padding-top: 200px;
    padding-bottom: 150px;
  }

  .about_title{
    grid-area: title;
  }

  .about_advantages{
    grid-area: advantages;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
  }

  .about_image{
    grid-area: image;
    width: 100%;
    height: 100%;

  }

  .wrapper_about__grid{
    display: grid;
    grid-template-areas: "title image" " advantages image";
    grid-template-columns: 1fr 50%;

    grid-gap: 30px;


  }
  .about_advantages_title{
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* or 140% */


    color: #0C1D2E;
  }


  .about_advantages_sub_title{
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-top: 1rem;
    /* or 144% */


    color: #7B889A;

  }

  .about_title{
    font-weight: 600;
    font-size: 40px;
    line-height: 56px;
    /* identical to box height, or 117% */


    color: #044582;
  }

  .wrapper_about{
    background: #EBF0F4;
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 768px){
  .wrapper_about__grid{
    display: grid;
    grid-template-areas: "title" "  image" "advantages";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 5px;
    padding-right: 4vw;
    padding-left: 4vw;

  }
  .about_advantages{
    margin-top: 20px;
    grid-template-columns: 1fr;
    padding: 0 20px;

  }

  .about_image{
    border-radius: 10px;
    overflow: hidden;

  }

}