.wrapper_contact{
  display: grid;
  grid-template-columns: 1fr 1fr ;
  grid-template-areas: "content maps";
  width: 100%;
}


.contact_banner{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;width: 100%;
  height: 100%;

}

.contact_info{
  position: relative;
  grid-area: content;
}

.maps{
  grid-area: maps;
}

.contact_title{
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #FFFFFF;
}

.contact__item{
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height:32px;
  display: block;
  color: #FFFFFF;
}

.wrapper_contact_info{
  position: relative;
  height: 100%;
  padding-top: 12vh;
padding-bottom: 20px;
  z-index: 3333;
}

.contact_adress{
  margin-top: 30px;
}

.contact_btn{
  margin-top: 70px;
}

@media screen and (max-width: 1024px) {
  .wrapper_contact_info{
    position: relative;
    height: 100%;
    padding-top:6vh;

    padding-bottom: 2vh;
    z-index: 3333;
  }


}


@media screen and (max-width: 768px) {
  .wrapper_contact{
    display: grid;
    grid-template-columns: 1fr ;

    grid-template-rows: auto auto ;
    grid-template-areas: " maps" "content";
    width: 100%;
  }
}