.wrapper_our_work_block{
  background: #EBF0F4;
  padding-top: 150px;
  padding-bottom: 150px;
}

.wrapper_our_work_items{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  grid-gap: 30px;
  grid-template-rows: 300px;
  margin-top: 50px;

}



.our_work_item{
  padding: 0px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.our_work_item_number{
  font-weight: 600;
  font-size: 72px;
  line-height: 86px;
  text-align: right;
  color: #FFFFFF;
  opacity: 0.2;
  margin-bottom: 0;
}

.number_color_1{
  background: #DFE6F0;
}

.number_color_2{
  background:  #C0DAF2;
}

.number_color_3{
  background:#528DC4;
}

.number_color_4{
  background: #044582;
}

.our_work_item_title__blue{
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  margin-bottom: 0;

  color: #044582;
}

.our_work_item_sub_title__blue{
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */


  color: #044582;
  margin-bottom: 0;
}

.our_work_item_title__white{
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  margin-bottom: 0;

  color: #ffffff;
}

.our_work_item_sub_title__white{
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */


  color: #ffffff;
  margin-bottom: 0;
}

.wrapper_our_work_icon{
  position: relative;
  height: 100%;
  & img {
    position: absolute;
    bottom: 2vh;
    left: 2vw;

  }
}

.boxs__left{
 margin-left: 2vw;
}

@media screen and (max-width: 1200px){


  .our_work_item_title__white{
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 150% */
    margin-bottom: 0;

    color: #ffffff;
  }

  .our_work_item_title__blue{
    font-weight: 600;
    font-size:18px;
    line-height: 36px;
    /* identical to box height, or 150% */
    margin-bottom: 0;

    color: #044582;
  }

  .wrapper_our_work_icon{
    position: relative;
    height: 100%;
    & img {
      position: absolute;
      bottom: 2vh;
      left: 0vw;

    }
  }

  .size_angar{
width: 159px;
  }
}


@media screen and (max-width: 1024px){
  .wrapper_our_work_items{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 21px;
    grid-template-rows: 300px 300px;
    margin-top: 50px;

  }
  .wrapper_our_work_icon{
    position: relative;
    height: 100%;
    & img {
      position: absolute;
      bottom: 2vh;
      left: 10vw;

    }
  }

  .our_work_item_title__white{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
    margin-bottom: 0;

    color: #ffffff;
  }

  .our_work_item_title__blue{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */
    margin-bottom: 0;

    color: #044582;
  }

  .wrapper_our_work_block{
    background: #EBF0F4;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 768px){
.item_up__top{
  margin-bottom: 40px;
  margin-top: -40px;
}

  .wrapper_our_work_items{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 30px;

    grid-template-rows: 300px 300px;
    margin-top: 13vh;
    padding-left: 4vw;
    padding-right: 4vw;

  }
  .wrapper_our_work_icon {
    position: relative;
    height: 100%;

    & img {
      position: absolute;
      bottom: 2vh;
      left: 8vw;

    }

  }

  .our_work_item_sub_title__white{
    display: none;
  }
  .our_work_item_sub_title__blue{
    display: none;
  }

  .wrapper_our_work_block{
    background: #EBF0F4;
    padding-top: 100px;
    padding-bottom: 200px;
  }
}


@media screen and (max-width: 480px){

  .wrapper_our_work_icon {
    position: relative;
    height: 100%;

    & img {
      position: absolute;
      bottom: 0vh;
      left: 0vw;

    }

  }

  .size_bus{
    width: 150px;
  }


  .our_work_item_title__blue{
    font-weight: 600;
    font-size: 13px;

  }

  .our_work_item_title__white{
    font-weight: 600;
    font-size: 13px;

  }

  .wrapper_our_work_items{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 30px;

    grid-template-rows: 250px 250px;
    margin-top: 13vh;
    padding-left: 4vw;
    padding-right: 4vw;

  }

  .size_angar{
    width: 139px;
  }

}

@media screen and (max-width: 414px){
  .size_car{
    width: 100px;
  }

  .our_work_item_title__blue{
    font-weight: 600;
    font-size: 13px;

  }

  .our_work_item_title__white{
    font-weight: 600;
    font-size: 13px;

  }
}

@media screen and (max-width: 375px){
  .size_car{
    width: 90px;
  }

  .size_angar{
    width: 120px;
  }

  .size_bus{
    width: 129px;
  }

  .size_box{
width: 90px;
  }
  .our_work_item{
    padding: 10px;
  }

}

@media screen and (max-width: 320px){
  .size_car{
    width: 90px;
  }

  .size_angar{
    width: 120px;
  }

  .size_bus{
    width: 129px;
  }

  .size_box{
    width: 90px;
  }
  .our_work_item{
    padding: 5px;
  }


  .wrapper_our_work_items{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    grid-gap: 20px;

    grid-template-rows: 200px 200px;
    margin-top: 13vh;
    padding-left: 2vw;
    padding-right: 2vw;

  }

  .our_work_item_title__blue{
    font-weight: 600;
    font-size: 12px;

  }

  .our_work_item_title__white{
    font-weight: 600;
    font-size: 12px;

  }

}