.swiper-pagination-bullet-active {
  background: #F47D0E !important;
}

.wrapper_slide_block {
  width: 100%;
  height: 100vh;
  max-height: 780px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #FFFFFF;

  @media screen and (max-width: 1025px) {
    background-position: center;
  }
}

.car {
  position: absolute;
  right: 5vw;
  bottom: -10vh;
  width: 550px;
}

.car02 {
  position: absolute;
  right: -5vw;
  top: 23vh;
}


.wrapper_main_swiper {
  position: relative;
}


.main_slider_title {
  position: relative;
  z-index: 7777;
  font-weight: bold;
  font-size: 64px;
  line-height: 77px;
  letter-spacing: 0.03em;
  text-transform: uppercase
}

.main_slider_sub_title {
  font-size: 24px;
  line-height: 32px;
  margin-top: 1rem;

}

.car04 {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 95vh;
}


.main_slider_wrapper_text {
  height: 100%;
  display: flex;
  align-items: center;
}

/*SLIDER*/


.carousel-control-prev {
  display: none !important;
}

.carousel-control-next {
  display: none !important;
}

.carousel-indicators {
  position: absolute;

  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  /* margin-right: 15%; */
  /* margin-left: 15%; */
  list-style: none;
}


@media screen and (max-width: 1200px) {
  .car {
    position: absolute;
    right: 5vw;
    bottom: -10vh;
    width: 480px;
  }

  .car02 {
    right: -4vw;
    top: 21vh;
    width: 679px;
  }

  .main_slider_title {
    font-weight: bold;
    font-size: 56px;
    line-height: 77px;
    letter-spacing: 0.03em;
    text-transform: uppercase
  }
  .main_slider_sub_title {
    font-size: 20px;
    line-height: 32px;
  }

  .car {
    position: absolute;
    right: 0vw;
    bottom: -10vh;
    width: 480px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 115px !important;
  }

}


@media screen and (max-width: 1366px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 90px !important;
  }
}


@media screen and (min-width: 1537px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 350px !important;
  }

  .car {
    position: absolute;
    right: 10vw;
    bottom: -1vh;
    width: 620px;
  }
}

@media screen and (min-width: 1441px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 164px !important;
  }

  .car {
    position: absolute;
    right: 0vw;
    bottom: -3vh;
    width: 630px;
  }
}

@media screen and (max-width: 1440px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 120px !important;
  }

  .car {
    position: absolute;
    right: 0vw;
    bottom: -3vh;
    width: 630px;
    max-width: 95vw;
  }
}


.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  width: 1200px !important;
  margin: auto !important;
  display: flex !important;
  bottom: 4vh !important;
}

.swiper-pagination-bullet {
  width: 80px !important;
  height: 5px !important;
  display: inline-block;
  border-radius: 0 !important;


}

@media screen and (max-width: 1024px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 24px !important;
  }

  .car02 {
    right: -4vw;
    top: 25vh;
    width: 600px;
    max-width: 95vw;
  }
}


@media screen and (max-width: 992px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 86px !important;
  }

  .car02 {
    right: -4vw;
    top: 37vh;
    width: 555px;
  }

  .car04 {
    right: -59vw;
    max-height: 100vw;
    top: auto;
    bottom: 0;
  }
}


.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 30px 0 0 !important;
}

@media screen and (max-width: 768px) {
  .car {
    position: absolute;
    right: 0vw;
    bottom: 0vh;
    width: 455px;
  }

  .car02 {
    right: -4vw;
    top: 91vw;
    width: 600px;
  }

  .main_slider_wrapper_text {
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 16vh;
    padding-left: 4vw;
  }

  .main_slider_title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #FFFFFF;
  }
  .main_slider_sub_title {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */


    color: #FFFFFF;

  }

  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 25px !important;
  }
}

@media screen and (max-width: 415px) {
  .car {
    position: absolute;
    right: 0vw;
    bottom: 0vh;
    width: 370px;
  }

  .car02 {
    right: 0vw;
    width: 370px;
    top: 100vw;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 18px !important;
  }
}


@media screen and (max-width: 375px) {
  .car {
    position: absolute;
    right: 0vw;
    bottom: 0vh;
    width: 340px;
  }

  .car02 {
    right: 0vw;
    width: 390px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {

    left: 18px !important;
  }
}


@media screen and (max-width: 320px) {
  .car {
    position: absolute;
    right: 0vw;
    bottom: 0vh;
    width: 310px;
  }


  .car02 {
    right: 0vw;
    width: 320px;
  }

  .main_slider_title {
    font-weight: bold;
    font-size: 42px;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-transform: uppercase
  }


  .swiper-pagination-bullet {
    width: 70px !important;
    height: 5px !important;
    display: inline-block;
    border-radius: 0 !important;


  }
}
