.footer_color_txt{
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: #7694A9;
}


.wrapper_footer{
  background: #0B2843;
}

.wrapper_footer_info{
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 30px;
}
.politics{
  color: #F47D0E;
}

.copyright{
margin-bottom: 0;
}

.synergy{

}
.footer_img_logo{
margin-bottom: 20px;
}

.right_info_block{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_info_block{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper_footer_info_mob{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 4vw;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px){
  .footer_img_logo{
  margin-bottom: 20px;
  margin-right: auto;
  padding-left: 4vw;

}
  .left_info_block{
    width: 100%;
  }
.mop_padding_footer{
  width: 100%;
  padding: 0px 4vw;
}

  .copyright{
    margin-top: 20px;
    width: 100%;
    padding: 0px 4vw;
  }

  .synergy{
    width: 100%;
    padding: 0px 4vw;
    margin-top: 20px;
    margin-left: auto;
    text-align: center;
    padding-bottom: 20px;
  }

}

.footer-mobile-wrapper-phones{
  display: flex;
  flex-direction: column;
}