.wrapper_header {
  padding-top: 20px;
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 100;
  height: 90px;
  z-index: 1111111;
  transition: 0.3s;


  & ul {
display: flex;
    justify-content:space-between ;
    align-items: center;
  }

  & li {
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    color: #FFFFFF;
  }



}

.header_logo {
  fill: #528DC4;
}

.btn_header{
  display: flex;
  place-content: center;
}

a {
  text-decoration: none;
}


@media screen and (max-width: 1024px){
  .wrapper_header {
    padding-top: 20px;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 100;
    height: 75px;
    z-index: 1111111;
    transition: 0.3s;


    & ul {
      display: flex;
      justify-content:space-between ;
      align-items: center;
    }

    & li {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
    }

    .header_li__item{
      font-weight: bold!important;
      font-size: 16px!important;
      line-height: 19px!important;
    }



  }

  .svg_logo{
    width: 137px;
    height:  33px;
  }
}


@media screen and (max-width: 768px){

  .header_li__item{
    display: none;
  }

  .wrapper_header {
    padding-top: 20px;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    z-index: 100;
    height: 90px;
    z-index: 1111111;
    transition: 0.3s;


    & ul {
      display: flex;
      justify-content:space-between ;
      align-items: center;
      padding-right: 4vw;
      padding-left: 4vw;
    }


  }
  .svg_logo{
    width: 137px;
    height:  43px;
  }

}